.breadscrum > span{
    font-size: 18px;
    line-height: 21px;
    text-align: justify;
    letter-spacing: 0.2325px;
    color: #3C3C3C;
    margin-left: 50px;
    padding-top: 9px !important;
}

.breadscrum > svg{
    width: 12px !important;
    margin-left: 50px;
}
.breadscrum{
    vertical-align: middle;
    position: relative;
    display: contents;
    margin-left: 25px;
    padding-top: 9px !important;
}