.table-container{
    max-height: 50vh;
    /* overflow: hidden; */
}
.table-responsive{
    max-height: 60vh;
    overflow-y: scroll;
}
.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color:#EBECF4; 
 }
 .table{
     text-align: left;
 }