:root{
    --primary: #262F46;
    --light: #ffff;
    --green: #3ac569;

}
.login-form{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}
.mt-50{
    margin-top: 25vh;
}
.login-button{
    background: var(--green) !important;
    color: white !important;
}
.green{
    color: var(--green);
}