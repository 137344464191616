.dropzone {
    /* background-color: #f8f9fa; 
    border: 2px dashed #6c757d; 
    transition: background-color 0.3s, border-color 0.3s; */
}
  
.dropzone.dragover {
    /* background-color:#61699d; 
    border-color: #2B388F; */
    filter: blur(3px); /* Efecto de desenfoque */
}

.dropzone p {
    color: #6c757d; /* Texto gris */
}

.dropzone.dragover p {
    color: #2B388F; /* Texto verde */
}  