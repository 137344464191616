form{
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.form-group input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 3;
}

.form-control:disabled, .form-control[readonly]{
    background-color: #ebecf4 !important;
    opacity: 1;
}

.form-group > h5{
    margin-top: 1.5rem!important;
    font-weight: 600 !important;
}

.form-group {
    margin-bottom: 1rem !important;
}
.custom-radio > .custom-control-input:checked~.custom-control-label::before{
    border-color: #2b388f;
    background-color: #2b388f;
}

.custom-radio{
    margin-left: 1rem;
}
.radio-organize{
    display: flex;
    flex-direction: row;
}
.custom-control{
    z-index: 1;
}
.custom-control-label{
    z-index: -17;
}