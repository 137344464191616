.sub-menu-container{
    display: flex;
    flex-direction: column;
    width: 12vw;
    min-width: 270px;
    overflow-x: overlay;
    z-index: 5;
}

.sub-menu-container ul{
    margin-left: 0.8vw;
}

.sub-menu-container .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle, 
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    border-color: inherit;
    box-shadow: none;
}

.btn-secondary.focus, .btn-secondary:focus {
    color: #3c3c3c !important;
    border-color: inherit;
    box-shadow: none !important;
}
.sub-menu-container a,
.sub-menu-container a:hover {
    text-decoration: none;
}

.sub-menu-container .btn-secondary {
    margin-left: 2.4vw;
    border: none;
    text-align: left;
    width: 8vw;
    color: #3c3c3c;
    background-color: inherit !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 17px !important;
    min-width: fit-content;
    text-decoration: none !important;
}

.sub-menu-container .btn-secondary:hover, 
.sub-menu-container .btn-secondary .active,
.sub-menu-btn .active .btn .btn-secondary{
    color: #2B388F !important;
}

.sub-menu-container li .active {
    color: white !important;
    background-color: rgb(43, 56, 143) !important;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    width: 7.5vw;
}

.sub-menu-container li > a{
    padding-left: 17px;
}

.sub-menu-container li{
    text-decoration: none !important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5vw;
    font-size: 0.8rem;
    list-style-type: none;
}

.sub-menu-container .links{
    border-radius: 4px;
    color: #2B388F !important;
}

.sub-menu-container span{
    text-decoration: none;
    padding-top: 10px !important;
    padding-bottom: 15px !important;
    margin-left: 2px !important;
    font-size: 17px !important;
}

.grid-container{
    display: grid;
    grid-template-columns: 0.5fr 1.7fr;
    grid-template-rows: 1fr;
}

.sub-menu-container >h4{
    margin-top: 3rem;
    margin-bottom: 4rem;
}