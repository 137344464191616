.border{
    border-color: #979797;
    border-style: solid;
    border-width: 2px;
} 
label{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}
input {
    border: none ;
    border-color: transparent ;
    margin-bottom: 0px !important;
}

.dm-b{
    margin-bottom: 0 !important;
}
.dm-c{
    margin-bottom: 7px !important;
}

.nomizor_welcome{
    position: absolute;
    top: 38vh;
    left: 42vw;
    z-index: -5;
}

.nomizor_welcome > img{
    width: 20vw;
}

#pc1 {
    opacity: 1;
    transition: opacity 1s;
}

#pc1.hide {
    opacity: 0;
    /* display: none; */
}