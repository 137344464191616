.tab-content span{
    display: inline-block;
}

.nav-tabs a, .tab-content span{
    text-decoration: none;
}

.nav-tabs a:hover{
    cursor: pointer;
}

.nav-tabs .nav-link{
    font-size: 1.05rem;
    font-weight: 600;
    color: #3c3c3c;
}

.nav-tabs .nav-link.active{
    color: #2b388f;
}

.nav-tabs .nav-item{
    text-align: center;
}

.table-non div{
    display: inline-block;
}