.search-control-with-border{
    width: 100%;
    height: 59px;
    border: 1px solid #979797 !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    font-size: 21px !important;
    font-weight: 400;
    padding: 20px;
}

.search-control-with-border input{
    font-weight: 400 !important;
}

.search-control-with-border:focus {
    outline: none;
}