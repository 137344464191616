.table-container{
    max-height: 50vh;
    /* overflow: hidden; */
}

.table-responsiver{
    display: block;
    max-height: 100vh;
    overflow-y: auto;
}
.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color:#EBECF4; 
 }