.users-list svg{
    padding-top: 0px !important;
    color: #737373;
}

.users-list-tr > th{
    border-top: none !important;
    border-bottom: none !important;
}

.users-list-tr-table > tr, .users-list-tr-table > tr > td{
    vertical-align: middle;
}