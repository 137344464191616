input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #979797;
}

input[type='radio']:checked:after {
    
    background-color: #2b388f;
    content: '';

}
textarea:focus, input:focus, input[type]:focus, select:focus {
    border-color: #979797 !important;
    box-shadow: 0 0 5px #2b388f !important;
    outline: 0 none !important;
    
}
