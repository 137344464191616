.page-item.active .page-link {
    background-color: rgb(43, 56, 143) !important;
    border-color: rgb(43, 56, 143) !important;
    color: white !important;
}

.page-link {
    color: rgb(43, 56, 143) !important;
}

.page-link:focus{
    box-shadow: none !important;
}