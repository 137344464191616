input[type="file"] {
    outline: 0;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    width: 10%;
  }

  .label {
    width: 100%;
    border: 2px solid #2B388F;
    background-color: white;
    border-radius: 15px;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 500ms ease;
    cursor: pointer;
    text-align: center;

  }
  .label i {
    display: block;
    font-size: 42px;
    padding-bottom: 16px;
  }
  .label i,
  .label .title {
    margin-left: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight:300;
    color: #2B388F;
    transition: all 200ms color;
  }
  .label:hover {
    background-color: #2B388F;
    color: white;
    border-color: #2B388F;
  }
  .label:hover i,
  .label:hover .title,
  .label:hover #svg {
    color: white !important;
  }

  .labelDisabled {
    width: 100%;
    border: 2px solid #ccc;
    background-color: #ccc;
    border-radius: 15px;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 500ms ease;
    cursor: pointer;
  }
  .labelDisabled i {
    display: block;
    font-size: 42px;
    padding-bottom: 16px;
  }
  .labelDisabled i,
  .labelDisabled .titleDisabled {
    margin-left: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight:300;
    color: white;
    transition: all 200ms color;
  }



