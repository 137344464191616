.loading-wrapper{
    position: fixed;
    top: 42vh;
    left: 49%;
    width: fit-content;
    z-index: 15;
    color: #2B388F;
}
.fc-daygrid-more-link{
    color: #2B388F;
}
.fc-daygrid-more-link:hover{
    text-decoration: none;
}
.loading-fc{
    background-color: #dfdfdf;
}
.lateral{
    background-color: rgb(235, 236, 244);
    height: 100vh;
    margin-bottom: 0px;
    padding: 2%;
    position: fixed !important;
    top: 1vh;
    right: 0vw;
    max-width: 20% !important;
}

.lateral-section{
    border-bottom: #dfdfdf solid 1px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
}

.lateral-section > h4{
    font-size: 22px;
    color: #404041;
    margin-bottom: 1.5rem;
}

.lateral-section label{
    color: #737373;
    font-size: small;
    width: 100%;
}

/* SWAL */

.swal2-icon.swal2-info{
    border-color: #2b388f;
    color: #2b388f;
}

.swal2-styled.swal2-confirm{
    background-color: #2b388f;
}

/* TAGS */

.tags-wrapper{
    height: auto;
    min-height: 15vh;
}

.tags{
    border-radius: 4px;
    padding: 2px;
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    width: fit-content;
    font-size: small;
    position: initial;
    display: inline-flex;
    text-align: center;
}

.tags > button{
    background-color: inherit;
    border: none;
    font-size: x-small;
    color: #737373;
    font-weight: 700;
}

.calendar-wrapper{
    /* width: 88%; */
    margin-left: auto;
    margin-right: auto;
}
.align-middle > div {
    text-align: right;
}
.modal-title{
    color: white;
}
/* Colors for events */

.fc-daygrid-event-dot{
    border: 4px solid #2b388f;
    border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #2b388f);
}

.fc-event-time{
    color: white !important;
    font-size: x-small;
}

.birthday-event, .tag-birthday{
    border-top: #3188BE solid 4px;
    background-color: #BED4E7 !important;
}
.tag-birthday{
    border: none;
    color:#3188BE !important;
}
.birthday-event > .fc-event-title{
    color:#3188BE !important;
}

.vacation-event, .tag-vacation{
    border-top: #EEAAF2 solid 4px;
    background-color: #FEE6FF  !important;
}
.tag-vacation{
    border: none;
    color:#EEAAF2 !important;
}

.vacation-event > .fc-event-main{
    color:#EEAAF2 !important;
}

.aniversary-event, .tag-aniversary{
    border-top: #9159D8 solid 4px;
    background-color: #E1D0F6 !important;
}
.tag-aniversary{
    border: none;
    color:#9159D8 !important;
}
.aniversary-event > .fc-event-title{
    color:#9159D8 !important;
}

.tool-event, .tag-tool{
    border-top: #E97D2B solid 4px;
    background-color: #EBD0C1 !important;
}
.tag-tool{
    border: none;
    color:#E97D2B !important;
}
.tool-event > .fc-event-title{
    color:#E97D2B !important;
}

.uniform-event, .tag-uniform{
    border-top: #dfc41c solid 4px;
    background-color: #fff4ae !important;
}
.tag-uniform{
    border: none;
    color:#dfc41c !important;
}
.uniform-event > .fc-event-title{
    color:#dfc41c !important;
}

.document-event, .tag-document{
    border-top: #929FD4 solid 4px;
    background-color: #c1c9e9 !important;
}
.tag-document{
    border: none;
    color:#929FD4 !important;
}
.document-event > .fc-event-title{
    color:#929FD4 !important;
}

.promotion-event, .tag-promotion{
    border-top: #bccf41 solid 4px;
    background-color: #f4ffb0 !important;
}
.tag-promotion{
    border: none;
    color:#bccf41 !important;
}
.promotion-event > .fc-event-title{
    color:#bccf41 !important;
}

.medical-event, .tag-medical{
    border-top: #ef59a0 solid 4px;
    background-color: #ffb4d7 !important;
}
.tag-medical{
    border: none;
    color:#ef59a0 !important;
}
.medical-event > .fc-event-title{
    color:#ef59a0 !important;
}

.summon-event, .tag-summon{
    border-top: #7ac10a solid 4px;
    background-color: #c6e694 !important;
}
.tag-summon{
    border: none;
    color:#7ac10a !important;
}
.summon-event > .fc-event-title{
    color:#7ac10a !important;
}

.audience-event, .tag-audience{
    border-top: #fc6b6b solid 4px;
    background-color: #ffb5b5 !important;
}
.tag-audience{
    border: none;
    color:#fc6b6b !important;
}
.audience-event > .fc-event-title{
    color:#fc6b6b !important;
}

.permission-event, .tag-permission{
    border-top: #9db12c solid 4px;
    background-color: #fffab5 !important;
    color:#9db12c !important;
}
.tag-permission{
    border: none;
    color:#9db12c !important;
}
.permission-event > .fc-event-main{
    color:#9db12c !important;
    padding-left: 14px;
}

.inability-event, .tag-inability{
    border-top: #8f0b88 solid 4px;
    background-color: #e0add1 !important;
}
.tag-inability{
    border: none;
    color:#8f0b88 !important;
}
.inability-event > .fc-event-main{
    color:#8f0b88 !important;
    padding-left: 14px;
}

.agreement-event, .tag-agreement{
    border-top: #3a9aaa solid 4px;
    background-color: #55dfff !important;
}
.tag-agreement{
    border: none;
    color:#3a9aaa !important;
}
.agreement-event > .fc-event-main{
    color:#3a9aaa !important;
    padding-left: 14px;
}

.agreement-event, .tag-agreement{
    border-top: #3a9aaa solid 4px;
    background-color: #55dfff !important;
}
.tag-agreement{
    border: none;
    color:#3a9aaa !important;
}
.agreement-event > .fc-event-main{
    color:#3a9aaa !important;
    padding-left: 14px;
}

/* Calendar Plugin Customization*/
.fc-event-title{
    font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal !important;
    font-weight: 500;
    font-size: 11px !important;
}

.fc .fc-bg-event{
    padding-top: 16%;
    opacity: 1;
    text-align: center;
}
.fc-daygrid-day-frame{
    max-height: 55px;
}

.fc-daygrid-event-harness:hover{
    cursor: pointer;
}

.fc .fc-daygrid-day-top{
    display: inline;
}

.fc .fc-daygrid-day-top > a{
    color: #404041;
    font-size: 15px;
    font-weight: 600;
}

.fc-toolbar-title{
    color: #2B388F;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
}

.fc-theme-standard th, .fc-col-header-cell .fc-day .fc-day-mon{
    border: none;
    border-bottom-color: #ddd;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.fc .fc-daygrid-body tr{
    border: 1px solid var(--fc-border-color, #ddd)
}

.fc .fc-col-header-cell-cushion{
    color: #404041;
    text-transform: capitalize;
    margin-top: 20px;
    margin-bottom: 20px;
}

.fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:focus {
    border: none;
    box-shadow: none;
}

.fc .fc-button-primary{
    color: #2B388F;
    background-color: white;
    border: none;
    font-size: small;
    font-weight: 500;
}

.fc .fc-button-primary:hover{
    background-color: #2B388F;
}

.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #2B388F;
}


/*Checkboxes*/
.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: rgb(235, 236, 244);
    border: 1px solid #979797;
    border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: rgb(235, 236, 244);
}

.control input:checked ~ .control_indicator {
    background: #293486;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #293486;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 11px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    margin-left: -0.44rem;
    margin-top: -0.44rem;
    background: #2e3a91;
    border-radius: 3rem;
    opacity: 0.3;
    z-index: 99999;
    transform: scale(0);
}
@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}
