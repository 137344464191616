.colaborador-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'picture description description description description'
    'picture info info info info';
}
.picture {
  grid-area: picture;
  padding: 1rem;
}
.description {
  grid-area: description;
}
.info {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1.5fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'id department salary antiquity'
    'id department salary antiquity';
  grid-area: info;
}
.id {
  grid-area: id;
}
.department {
  grid-area: department;
}
.salary {
  grid-area: salary;
}
.antiquity {
  grid-area: antiquity;
}
