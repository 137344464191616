

.background{
    background-color: mediumpurple;
    height: 100vh;
}
.fullViewport{
    height: 100vh;
}
#sidebar-container{
    min-height: 100vh;
}
.avatar{
    max-width: 125px;
}
.bg-primary{ background-color: var(--primary)}
.bg-green{ background-color: var(--green)}
.bg-light{ background-color: var(--gray)}