.grid-alta {
  display: grid;
  height: 100%;
  grid-template-columns: 0.4fr 1.6fr;
  grid-template-rows: 0.2fr 1.8fr;
  grid-gap: 15px 1px;
  grid-template-areas:
    'ComponentDescript ComponentDescript'
    'Opciones Forms ';
}
.ComponentDescript {
  grid-area: ComponentDescript;
}
.Opciones {
  grid-area: Opciones;
  height: fit-content;
}
.Forms {
  grid-area: Forms;
}

input{
  margin-bottom: 1rem !important;
  border: none !important;
  border-color: transparent !important;
}

