.calendario-modal{
    top: 20vh;
}

@media (min-width: 1024px) {
    .calendario-modal{
        width: 627px;
        min-width: 627px;
    }
}

.calendar-form .form-group {
    margin-bottom: 1rem !important;
}
.calendar-form select.placeholder{
    color: #B1B1B1
}
.calendar-form input[type="date"]{
    width: 222px;
}
.calendar-form select, .calendar-form select:focus  {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    border: none;
}
.calendar-form input, .calendar-form select {
    margin-bottom: 0 !important;
}

.modal-header{
    background-color: #2b388f;
    color: white;
    height: 68px;
}
.modal-footer{
    padding: 0.25rem !important;
    border-top: none !important;
}

.modal-body{
    padding: 2rem !important;
}

.modal-header button{
    color: white;
    text-shadow: none;
    opacity: 1;
}

.modal-header button:hover{
    color: white;
}

.calendar-form .form-group{
    flex-wrap: inherit !important;
}

.calendar-form .form-group .form-icon{
    margin-top: 7px;
}

.calendar-form .form-group label{
    font-family: 'Roboto' sans-serif;
    margin-top: -1px;
    font-size: 16px !important;
    color: #3c3c3c;
}

.form-icon{
    margin-right: 10px;
}