
.profile-picture {
    width: 100% !important;
}
.card {
    margin-left: 5rem;
    max-width: 35rem;
    max-height: 35rem;
    margin-top: 3rem;
}

.grid-profile {
    display: grid;
    grid-template-columns: 0.2fr 0.6fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1px 1px;
    justify-items: center;
}

.list-container {
    width: 90%;
    margin-top: 3rem;
}
.page-title{
    font-family: AvenirNext, sans-serif ;
    color: #3ac569;
}