.roles-list svg{
    padding-top: 0px !important;
    color: #737373;
}

.roles-list-tr > th{
    border-top: none !important;
    border-bottom: none !important;
}

.roles-list-tr-table a{
    color: #2b388f;
}
.roles-list-tr-table a:hover{
    text-decoration: none;
    color: #2b388f;
}

.roles-list-tr-table > tr, .roles-list-tr-table > tr > td{
    vertical-align: middle;
}

.roles-list-tr-table a > p{
    margin-bottom: 0;
}

.permisos-form h5{
    margin-left: 10px;
    text-align: center;
}
.permisos-form .col-form-label> div{
    text-align: center;
}
