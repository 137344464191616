h5 {
    color:#2B388F;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: justify;
    letter-spacing: 0.258333px;
    margin-left: 15px;
}
.labelGray{
    color:#3C3C3C;
    font-size: 17px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.219583px;
}
.label{
    width: 174px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-color: #2b388f !important;
}

.form-check-label{
    color: #B1B1B1 !important;
    padding-left: 3px !important;
}

.custom-radio{
    margin-left: 0 !important;
    /* padding-left: 0 !important; */
}
.form-check{
    padding-left: 3px !important;
}
