.modal-body {
  text-decoration: none;
  color: #404041 !important;
}

.modal-body .active {
  text-decoration: none;
  color: #404041 !important;
}

.label-checkbox {
  color: #404041 !important;
}
